import {Box, BoxProps, Typography} from "@mui/material";
import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {footerHeight, palette} from "../../constants";
import {Link} from "react-router-dom";
const logo = "/footer-logo.svg"

type IProps = {enableDayStreak?: boolean} & BoxProps;

const useStyles = makeStyles()((theme) => ({
	footer: {
		minHeight: footerHeight,
		background: palette.background,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: "relative",
		width: "100%",
		padding: "10px",
		boxSizing: "border-box",
		"& p": {
			opacity: ".5"
		}
	},
	container: {
		maxWidth: "100%",
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "flex-start",
		gap: "8px 20px",
		whiteSpace: "nowrap",
		position: "relative"
	},
	logoContainer: {
		display: "flex",
		alignItems: "center",
		gap: "5px",
		width: "fit-content",
		"img": {
			height: "20px",
			margin: "3px"
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%"
		}
	}
}));

export const Footer: FC<IProps> = ({title, enableDayStreak, ...boxProps}) => {
	const {classes} = useStyles();

	return (
		<Box className={classes.footer} {...boxProps}>
			<Box className={classes.container}>
				<Box className={classes.logoContainer}>
					<Typography>Powered by </Typography>
					<img src={logo} alt="logo" />
				</Box>
				<Box>
					<Link to={"https://www.buildwitt.com/privacy-policy"}>
						<Typography>Privacy Policy</Typography>
					</Link>
				</Box>
				<Box>
					<Link to={"https://info.buildwitt.com/training-terms-and-conditions"}>
						<Typography>Terms & Conditions</Typography>
					</Link>
				</Box>
				<Box>
					<Link to={"https://www.buildwitt.com/upload-policy"}>
						<Typography>DMC Policy</Typography>
					</Link>
				</Box>
			</Box>
		</Box>
	);
};
