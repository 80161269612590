import {AuthContext, INotificationSeverity, NotificationContext, useApi} from "@plumeuk/shapeshift-identity";
import {createContext, ReactElement, FC, useState, useEffect, useContext} from "react";
import {Socket, io} from "socket.io-client";
import {PageBaseContext} from "./pageBaseContext";
import {useNotifications} from "@plumeuk/shapeshift-common/hooks";

const SERVER_URL = process.env.REACT_APP_API_HOST;
if (!SERVER_URL) throw new Error("REACT_APP_API_HOST required");

type SocketContextState = Socket | undefined;
const initialState: SocketContextState = undefined;
const SocketContext = createContext<SocketContextState>(initialState);

interface IProps {
	children: ReactElement;
}

const SocketProvider: FC<IProps> = ({children}) => {
	const {notify} = useContext(NotificationContext);
	const [socket, setSocket] = useState<Socket | undefined>(undefined);
	const {user, authenticated} = useContext(AuthContext);
	const [{data: socketJwt}] = useApi<boolean>("/api/access");
	const [,setPageBaseContext] = useContext(PageBaseContext);
	const {notifications, getNotifications} = useNotifications(1, 0);

	useEffect(() => {
		if (!socketJwt || !authenticated || !user) return;

		const username = user?.email;
		if (!username) return;

		const socket = io(SERVER_URL, {
			auth: {
				strategy: "jwt",
				token: socketJwt
			}
		});

		const handleNotification = (e: { data: { body: string; subject: string } }): void => {
			const parser = new DOMParser();
			const doc = parser.parseFromString(e.data.body, "text/html");
			const body = doc.body.textContent || "";

			notify(body, e.data.subject, INotificationSeverity.neutral, 5000);
			getNotifications();
		};

		socket.on("connect", () => {
			socket.on("notification", handleNotification);
		});

		setSocket(socket);

		return () => {
			socket.disconnect();
			socket.close();
		};
	}, [user, socketJwt, authenticated]);

	useEffect(() => {
		if(notifications?.totalCount === undefined)
			return;
		const notificationCount = notifications?.totalCount;
		setPageBaseContext(prev => ({...prev, notificationCount}))
	}, [notifications])

	return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};

export {SocketProvider, SocketContext};
