import {FC, useContext} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Drawer, Typography} from "@mui/material";
import {palette} from "../../constants";
import {IconCreateCourse} from "../../icons/IconCreateCourse";
import {IconAdminUsers} from "../../icons/IconAdminUsers";
import {IconAdminCourses} from "../../icons/IconAdminCourses";
import {IconAdminReports} from "../../icons/IconAdminReports";
import {IconAdminSupport} from "../../icons/IconAdminSupport";
import {IconSettings} from "../../icons/IconSettings";
import {Link, useLocation} from "react-router-dom";
import {PageBaseContext} from "../../contexts/pageBaseContext";
import {Quiz} from "@mui/icons-material";

const useStyles = makeStyles()((theme) => ({
	sideMenu: {
		width: "283px",
		height: "100%",
		borderRight: "1px solid rgba(255,255,255,0.1)",
		"[class*=' MuiDrawer-paper']": {
			paddingTop: "0 !important"
		}
	},
	drawer: {
		width: "283px !important",
		height: "100%",
		transition: "all .3s ease-in-out",
		"& .MuiDrawer-paper": {
			position: "relative",
			height: "100%",
			backgroundColor: "#191919",
			borderRight: "1px solid rgba(255,255,255,0.1)",
			paddingTop: theme["toolbarHeight"],
			transition: "all .3s ease-in-out",
			width: "283px",
			overflow: "hidden",
			zIndex: theme.zIndex.appBar - 1,
			boxSizing: "border-box"
		}
	},
	header: {
		width: "223px",
		boxSizing: "border-box",
		padding: "16px 20px",
		borderBottom: "1px solid " + palette.midGrey4
	},
	companyName: {
		fontSize: "14px",
		fontWeight: 700
	},
	subtitle: {
		fontSize: "12px"
	},
	links: {
		"& a": {
			textDecoration: "none",
			color: theme.palette.common.white
		},
		padding: "24px 20px",
		display: "flex",
		flexDirection: "column",
		gap: "25px"
	},
	link: {
		cursor: "pointer",
		fontSize: "14px",
		fontWeight: 400,
		display: "flex",
		gap: "10px",
		"& svg": {
			width: "16px"
		}
	},
	activeLink: {
		color: theme.palette.primary.main,
		"& path": {
			fill: theme.palette.primary.main
		}
	},
	sectionTitle: {
		fontSize: "11px",
		fontWeight: 700
	}
}));

export const AdminSideMenuContent: FC = () => {
	const {cx, classes} = useStyles();
	const location = useLocation();
	const [pageBaseContext] = useContext(PageBaseContext);

	const isActive = (e: string): boolean => {
		return location.pathname.endsWith(e)
	}

	const linkElement = (link: string, name: string, icon: JSX.Element): JSX.Element =>
		<Link to={link}>
			<Box className={cx(classes.link, isActive(link) ? classes.activeLink : undefined )}>{icon}{name}</Box>
		</Link>

	return (
		<Box className={classes.sideMenu}>
			<Drawer
				className={classes.drawer}
				variant="permanent"
			>
				<Box className={classes.header}>
					<Typography className={classes.companyName}>{(pageBaseContext.ownedCohort && pageBaseContext.ownedCohort?.title) ?? ".."}</Typography>
					<Typography className={classes.subtitle}>Content Manager</Typography>
				</Box>
				<Box className={classes.links}>
					{linkElement("courses/create", "Create course", <IconCreateCourse />)}
					<Typography className={classes.sectionTitle}>MANAGEMENT</Typography>
					{linkElement("users", "Users", <IconAdminUsers />)}
					{linkElement("courses", "Courses", <IconAdminCourses />)}
					{linkElement("lessons", "Lessons", <IconAdminCourses />)}
					{linkElement("quizzes", "Quizzes", <Quiz />)}
					{linkElement("reports", "Reports", <IconAdminReports />)}

					<Typography className={classes.sectionTitle}>GENERAL</Typography>
					{/* {linkElement("support", "Support", <IconAdminSupport />)} */}
					{linkElement("settings", "Settings", <IconSettings />)}
				</Box>
			</Drawer>
		</Box>
	);
}