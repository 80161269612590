import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Box, Button, ButtonProps, CircularProgress, Typography} from "@mui/material";

const useStyles = makeStyles()((theme) => ({
	header: {
		display: "flex",
		justifyContent: "space-between"
	},
	title: {
		fontWeight: 600,
		fontSize: "2em"
	},
	subtitle: {
		fontWeight: 400,
		fontSize: "18px",
		opacity: "0.8"
	},
	buttons: {
		display: "flex",
		gap: "8px",
		"& > button": {
			height: "38px",
			padding: "0px 16px",
			fontSize: "12px",
			borderRadius: "5px",
			display: "flex",
			gap: "8px",
			"& > svg": {
				width: "17px"
			}
		}
	}
}));

interface ICourseheaderContentProps {
	title: string,
	subtitle: string,
	actionButtons?: (null | ({label: string, loading?: boolean} & Omit<ButtonProps, "label" | "children">))[]
}

export const AdminHeader: FC<ICourseheaderContentProps> = ({title, subtitle, actionButtons}) => {
	const {classes} = useStyles();
	return (
		<Box className={classes.header}>
			<Box>
				<Typography className={classes.title}>{title}</Typography>
				<Typography className={classes.subtitle}>{subtitle || "\u00A0"}</Typography>
			</Box>
			<Box className={classes.buttons}>
				{(actionButtons ?? []).filter(e => !!e).map((e, i) => <Button key={"action-btn-" + i} {...e}>{e?.loading ? <CircularProgress size={20}/> : e?.label}</Button>)}
			</Box>
		</Box>
	);
}