import {Box, BoxProps, Typography, duration} from "@mui/material"
import {FC} from "react"
import {API} from "@plumeuk/shapeshift-identity";
import {makeStyles} from "tss-react/mui";
import type {ICourseCurriculumModule, ILesson} from "@plumeuk/shapeshift-types";
import type {ICourseModule} from "@plumeuk/shapeshift-types/ICourse";
import {Check, CheckBox, Quiz} from "@mui/icons-material";
import VideoPreviewSlim from "../../pages/TrainingFeedPage/VideoPreviewSlim";
import {palette} from "../../constants";
import {IconClock} from "../../icons/IconClock";
import {formatTimeSecondsApprox} from "@plumeuk/shapeshift-common/common";

interface IProps extends BoxProps {
	module: ICourseModule | ICourseCurriculumModule,
}

const useStyles = makeStyles<{complete: boolean, active: boolean}>()((theme, {complete, active}) => ({
	moduleSummaryCard: {
		cursor: "pointer",
		transition: "all .3s ease-out",
		"& *": {
			textDecoration :"none !important"
		},
		width:  "100%",
		display: "flex",
		flexDirection: "row",
		gap: "14px",
		textAlign: "left",
		boxSizing: "border-box",
		borderRadius: "16px",
		padding: "14px",
		background: palette.grey05,
		backgroundClip:  "padding-box",
		flexGrow: 2,
		position: "relative",
		border: "1px solid " + ((complete || active) ? theme.palette.primary.main : palette.grey05),
		"[class*='previewImageSvg']": {
			width: "32px!important",
			height: "32px",
			svg: {
				width: "44px",
				height: "44px",
				transform: "translate(-6px, -6px)",
				marginRight: "auto",
				marginLeft: "auto"
			}
		},
		"&:hover": {
			background: palette.grey10
		}
	},
	videoImage: {},
	title: {
		fontWeight: 600,
		fontSize: "15px",
		overflow: "hidden",
		display: "-webkit-box",
		"-webkit-box-orient": "vertical",
		"-webkit-line-clamp": "4"
	},
	blankPreview: {
		borderRadius: "5px",
		overflow: "hidden",
		width: "90px",
		height: "70px",
		backgroundColor: theme.palette.primary.main,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		svg: {
			fill: theme.palette.common.white,
			width: "38px",
			height: "38px"
		}
	},
	blankButton: {
		backgroundColor: "transparent",
		padding: "0px",
		border: "none",
		lineHeight: "1em",
		":hover": {
			backgroundColor: "transparent",
			border: "none"
		}
	},
	completedCheck: {
		opacity: 1,
		position: "absolute",
		width: "20px",
		height: "20px",
		display: "flex",
		justifyContent: "center",
		textAlign: "center",
		flexDirection: "column",
		borderRadius: "100%",
		background: theme.palette.primary.main,
		top: -8,
		right: -8,
		zIndex: 10,
		"& svg": {
			width: "15px",
			height: "15px",
			margin: "auto",
			"& path": {
				fill: theme.palette.common.black
			}
		}
	},
	itemDetailsContainer: {
		border: "none",
		"h3": {
			textDecoration: "none"
		}
	},
	durationContainer: {
		height: "27px",
		boxSizing: "border-box",
		display: "flex",
		gap: "5px",
		"& p": {
			fontSize: "12px",
			fontWeight: 600,
			color: palette.grey60 + "!important"
		},
		"& svg": {
			height: "18px",
			width: "18px",
			top: "2px",
			position: "relative",
			"& path": {
				fill:  palette.grey60 + "!important"
			}
		}
	}
}));


export const ModuleSummaryCard:FC<IProps> = ({className, module, ...props}) => {
	const {classes, cx} = useStyles({complete: module.complete, active: false});
	const duration = (module as any)?.videoLength ?? (module as any)?.duration

	const getVideoThumbnail = (module: ICourseModule | ICourseCurriculumModule | null): string | undefined => {
		if (module?.type === "lesson") {
			const lesson = module as ILesson;
			if (lesson.videoThumbnailUrl) {
				return lesson.videoThumbnailUrl;
			}

			return "/holding_image.png"
		}

		return undefined;
	};

	const videoThumbnail = getVideoThumbnail(module);

	return (
		<Box className={cx(classes.moduleSummaryCard, className)} {...props}>
			{module.complete && <Box className={classes.completedCheck}>
				<Check/>
			</Box>}
			<Box className={classes.blankButton}>
				{(videoThumbnail)
					? <VideoPreviewSlim disablePlayIcon className={classes.videoImage} videoUrl={videoThumbnail} />
					: <Box className={classes.blankPreview}>
						{module.type === "survey" ? <CheckBox/> : <Quiz />}
					</Box>
				}
			</Box>
			<Box>
				<Box className={classes.itemDetailsContainer}>
					{duration && <Box className={classes.durationContainer}>
						<IconClock />
						<Typography>{formatTimeSecondsApprox(duration)}</Typography>
					</Box>}
					<Typography className={classes.title} variant="h4">{module.title}</Typography>
				</Box>
			</Box>
		</Box>
	)
}