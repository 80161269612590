import {Box, BoxProps, Button, Typography} from "@mui/material"
import {FC} from "react"
import {API} from "@plumeuk/shapeshift-identity";
import {makeStyles} from "tss-react/mui";
import type {ILesson, IModuleScheduled} from "@plumeuk/shapeshift-types";
import type {ICourseModule} from "@plumeuk/shapeshift-types/ICourse";
import VideoPreview from "../../pages/TrainingFeedPage/VideoPreview";
import {LinearProgress} from "@mui/material";
import {Check, CheckBox, Quiz} from "@mui/icons-material";
import {getFontStyles} from "../../templates/defaultTheme";
import {WYSIWYG} from "@plumeuk/shapeshift-common/wysiwyg";
import {Link} from "react-router-dom";
import {palette} from "../../constants";

interface IProps extends BoxProps {
	module: IModuleScheduled
}

const useStyles = makeStyles<{complete: boolean}>()((theme, {complete}) => ({
	itemContent: {
		display: "flex",
		flexDirection: "column",
		borderRadius: "16px",
		textAlign: "left",
		overflow: "hidden",
		boxSizing: "border-box",
		width: "340px",
		maxWidth: "340px",
		background: theme.palette.background.paper,
		padding: 0,
		flexGrow: 2,
		position: "relative",
		transition: "transform 0.3s ease-out",
		"[class*='previewImageSvg']": {
			width: "32px!important",
			height: "32px",
			svg: {
				width: "44px",
				height: "44px",
				transform: "translate(-6px, -6px)",
				marginRight: "auto",
				marginLeft: "auto"
			}
		},
		"& > *": {
			opacity: complete ? 0.25 : 1
		},
		"&:hover": {
			"[class*='btn']": {
				background: palette.orange,
				transition: "0.3s"
			}
		}
	},
	completedCheck: {
		opacity: 1,
		position: "absolute",
		top: 10,
		right: 10,
		zIndex: 10,
		"& svg": {
			width: "35px",
			height: "35px",
			border: "2px solid " + theme.palette.primary.main,
			borderRadius: "100%",
			padding: "10px",
			"& path": {
				fill: theme.palette.primary.main
			}
		}
	},
	title: {
		marginBottom: "0",
		overflow: "hidden",
		textOverflow: "ellipsis",
		display: "-webkit-box",
		WebkitLineClamp: "3",
		WebkitBoxOrient: "vertical"
	},
	itemSubTitle: {
		fontSize: "17px",
		overflow: "hidden",
		textOverflow: "ellipsis",
		display: "-webkit-box",
		WebkitLineClamp: "2",
		WebkitBoxOrient: "vertical"
	},
	description: {
		height: "67px",
		margin: "7px 0",
		color: theme.palette.text.primary,
		display: "-webkit-box",
		webkitLineClamp: "3",
		lineClamp: "3",
		webkitBoxOrient: "vertical",
		overflow: "hidden",
		textOverflow: "ellipsis",
		"*": {
			...getFontStyles(theme.typography.body2),
			margin: 0,
			padding: 0
		},
		"h1,h2,h3,h4,h5,iframe, ul, img": {
			display: "none"
		}
	},
	chip: {
		fontWeight: 600
	},
	blankPreview: {
		width: "calc(100% + 60px)",
		height: "220px",
		top: "0",
		position: "relative",
		left: "-30px",
		overflow: "hidden",
		backgroundColor: theme.palette.primary.main,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		svg: {
			fill: theme.palette.common.white,
			width: "38px",
			height: "38px"
		}
	},
	progressContainer: {
		" & h6": {
			...getFontStyles(theme.typography.body1)
		},
		display: "flex",
		flexDirection: "row",
		gap: "10px",
		alignItems: "center",
		"[role='progressbar']": {
			flexGrow: 1
		}
	},
	blankButton: {
		backgroundColor: "transparent",
		padding: "0px",
		border: "none",
		width: "100%",
		lineHeight: "1em",
		height: "220px",
		":hover": {
			backgroundColor: "transparent",
			border: "none"
		}
	},
	cardContents: {
		display: "flex",
		flexFlow: "wrap",
		height: "calc(100% - 220px)",
		padding: "25px 30px",
		boxSizing: "border-box"
	},
	itemDetailsContainer: {
		margin: "5px 0 15px",
		border: "none",
		flexGrow: 3,
		display: "flex",
		gap: "10px",
		flexDirection: "column",
		"& h3": {
			[theme.breakpoints.down("sm")]: {
				fontSize: "19px"
			}
		}

	},
	cardBottom: {
		marginTop: "auto",
		width: "100%",
		paddingTop: "8px",
		marginBottom: "8px"
	},
	itemLink: {
		display: "flex",
		flexFlow: "wrap",
		height: "100%"
	},
	btn: {
		marginTop: "20px",
		width: "100%",
		"&:hover": {
			opacity: 1
		}
	}
}));


export const TrainingFeedModule:FC<IProps> = ({className, module}) => {
	const {classes, cx} = useStyles({complete: module.complete});

	const getModuleDescription = (module: ICourseModule | null): string => {
		return module?.subtitle ?? "<p></p>";
	}

	const getVideoUrl = (module: ICourseModule | null): string | undefined => {
		if (module?.type === "lesson") {
			const videoUrl = (module as ILesson).videoUrl
			return videoUrl
		}
		return undefined;
	}

	const getVideoTime = (module: ICourseModule | null): number | undefined => {
		if (module?.type === "lesson") {
			return (module as ILesson).videoTime
		}
		return undefined;
	}

	const getVideoLength = (module: ICourseModule | null): number | undefined => {
		if (module?.type === "lesson") {
			return (module as ILesson).videoLength
		}
		return undefined;
	}

	const getProgress = (module: ICourseModule | null): number => {

		if (module?.complete) {
			return 100;
		}

		if (module?.type === "lesson") {
			const videoTime = getVideoTime(module);
			const videoLength = getVideoLength(module);
			if (!videoTime || !videoLength) {
				return 0;
			}
			return Math.min(Math.round((videoTime / videoLength) * 100), 100);
		}

		return 0;
	}

	const getVideoThumbnail = (module: ICourseModule | null): string | undefined => {
		if (module?.type === "lesson") {
			const lesson = module as ILesson;
			if (lesson.videoType === "mux") {
				return `https://image.mux.com/${lesson.videoUrl}/thumbnail.jpg?time=10&width=100&72`
			}

			if (lesson.videoType === "brightcove" && lesson.videoUrl) {
				const url = process.env.NEXT_PUBLIC_APP_API_HOST;
				return `${url}${API.videoThumbnail(lesson.videoUrl ?? "", "brightcove")}`;
			}

			if (lesson.videoThumbnailUrl) {
				return lesson.videoThumbnailUrl;
			}

			return "/holding_image.png"
		}

		return undefined;
	};

	const progress = getProgress(module);
	const videoUrl = getVideoUrl(module);
	const videoThumbnail = getVideoThumbnail(module);

	return (
		<Box className={cx(classes.itemContent, className)}>
			<Link className={classes.itemLink} to={"/feed/" + module.course.slug + "/" + module.type + "/" + module.slug}>

				{module.complete && <Box className={classes.completedCheck}>
					<Check/>
				</Box>}
				<Box className={classes.blankButton}>
					{(videoUrl && videoThumbnail)
						? <VideoPreview videoUrl={videoThumbnail} />
						: <Box className={classes.blankPreview}>
							{module.type === "survey" ? <CheckBox/> : <Quiz />}
						</Box>
					}
				</Box>

				<Box className={classes.cardContents}>
					<Box className={classes.itemDetailsContainer}>
						<Typography className={classes.title} variant="h4">
							{module.title}
						</Typography>
						{module?.course?.title &&
							<Typography className={classes.itemSubTitle} variant="h5">
								In {module.course.title}
							</Typography>
						}
						<WYSIWYG className={classes.description}>{getModuleDescription(module)}</WYSIWYG>
					</Box>

					<Box className={classes.cardBottom}>
						<Box className={classes.progressContainer}>
							<LinearProgress value={progress ?? 0} variant="determinate" />
							<Typography variant="subtitle1">{progress}%</Typography>
						</Box>

						<Button className={classes.btn}>{module.type === "lesson" ? "Watch lesson" : "Go"}</Button>
					</Box>
				</Box>
			</Link>
		</Box>
	)
}