import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import {ICourseEntity, ICourseEntityPayload} from "@plumeuk/shapeshift-types";
import {useNavigate, useParams} from "react-router-dom";
import Editor from "../../../../components/admin/WYSIWYG/Editor";
import AdminCourseCurriculum from "./AdminCourseCurriculum/CourseCurriculum";
import {IDropzoneFile} from "@plumeuk/shapeshift-common/fileDropzone/fileDropzone";
import {AdminInputText, AdminEntityPageContainer, AdminFileInput, AdminFieldContainer, AdminInputSwitch, AdminInputSelect} from "@plumeuk/shapeshift-common/admin"
import {cohortCourseValidationDoc} from "../../../../validationDocs/user/cohortCourseValidationDoc";
import {APIState} from "@plumeuk/shapeshift-identity";

interface IProps {}

const useStyles = makeStyles()((_theme) => ({
	adminLessonPage: {}
}));

const template: ICourseEntityPayload = {
	title: "", slug: "", content: "", description: "", curriculum: [],
	featuredImage: null
}

const contentTypes: string[] = ["Job Training", "Safety", "Personal Development", "Professional Development"]

export const AdminCoursePage: FC<IProps> = () => {
	const {id: courseId} = useParams();
	const {classes} = useStyles();
	const navigate = useNavigate();
	const entityId = courseId ? parseInt(courseId) : undefined;

	const ICourseEntityToICourseEntityPayload = (e: ICourseEntity): ICourseEntityPayload => {
		const {title, slug, content, id, createdAt, updatedAt, privacy, description, linearProgression} = e
		const featuredImage = e.featuredImage?.id
		const contentType = (e as any).contentType;
		const payload = {
			title, slug, content, id, createdAt, updatedAt, privacy, description, contentType, featuredImage, linearProgression
		}
		return payload;
	}

	const handleOnSaveResponse = (e: APIState<ICourseEntity>): void => {
		if(!e.data)
			return;

		navigate("../" + e.data.id.toString())
	}

	const handleOnGetResponse = (entityApiResponse: APIState<ICourseEntity>): void => {
		if(!entityApiResponse.data || entityApiResponse.isLoading)
			return;

		if(entityApiResponse.statusCode === 404){
			navigate("../")
		}

		if(!entityApiResponse.data)
			return;

		if(entityId && entityApiResponse.data.id !== entityId){
			navigate("../" + entityApiResponse.data.id.toString() + "?locale=" + entityApiResponse.data.locale)
		}
	}

	const handleOnDeleteResponse = (deleteApiResponse: APIState<ICourseEntity>): void => {
		if(!deleteApiResponse.data || deleteApiResponse.isLoading)
			return;

		if(deleteApiResponse.statusCode === 200){
			navigate("../")
		}
	}

	return (
		<Box className={classes.adminLessonPage}>
			<AdminEntityPageContainer<ICourseEntity, "course">
				entityId={courseId ? parseInt(courseId) : undefined}
				entityToPayload={ICourseEntityToICourseEntityPayload}
				validationDoc={cohortCourseValidationDoc}
				template={template}
				onSaveResponse={handleOnSaveResponse}
				onDeleteResponse={handleOnDeleteResponse}
				onGetResponse={handleOnGetResponse}
				type="course"
			>
				{({formData, setFormData, entity, locale, files, setFiles, validation}) => <>
					{/* <AdminInputText label="Slug" onChange={e => setFormData(prev => ({...prev, slug: e.target.value}))} value={formData.slug}/> */}
					<AdminInputText error={validation?.[0]?.title} required label="Title" onChange={e => setFormData(prev => ({...prev, title: e.target.value}))} value={formData.title} />
					<AdminInputText error={validation?.[0]?.description} label="Description" onChange={e => setFormData(prev => ({...prev, description: e.target.value}))} value={formData.description} />
					<AdminFieldContainer error={validation?.[0]?.content} label="Content"><Editor onChange={e => setFormData(prev => ({...prev, content: e.target.value}))} value={formData.content ?? ""} /></AdminFieldContainer>
					{locale === "en" && <AdminInputSwitch error={validation?.[0]?.linearProgression} label="Linear Progression" onChange={() => setFormData(prev => ({...prev, linearProgression: !prev.linearProgression}))} checked={formData.linearProgression} />}
					{locale === "en" && <AdminFieldContainer error={validation?.[0]?.curriculum} required label="Curriculum"><AdminCourseCurriculum initialData={entity} onChange={e => setFormData(prev => ({...prev, curriculum: e}))}/></AdminFieldContainer>}
					{locale === "en" && <AdminInputSwitch error={validation?.[0]?.privacy} label="Share" onChange={(e) => setFormData(prev => ({...prev, privacy: e.target.checked ? "global" : "cohort"}))} checked={formData.privacy === "global"} />}
					{locale === "en" && <AdminFileInput error={validation?.[0]?.featuredImage} required label="Featured Image" onChange={e => {
						if(Array.isArray(e)){
							if(typeof e[0] === "number")
								return; //featured image is single
							else {
								const f = e as IDropzoneFile[];
								setFiles(prev => ({...prev, featuredImage: f}));
							}
						}
						else if(e !== null)
							setFormData(prev => ({...prev, featuredImage: e}))
						else
							setFormData(prev => ({...prev, featuredImage: null}))
					}} files={files.featuredImage ?? []} value={formData.featuredImage} initialValue={entity?.featuredImage}/>}
					{locale === "en" && <AdminInputSelect<string> error={(validation?.[0] as any)?.contentType} required label={"Content Type"} onChange={e => setFormData(prev => ({...prev, contentType: e.target.value}))} options={contentTypes.map(e => ({label: e, value: e}))} value={(formData as any).contentType?.toString() ?? ""}/>}
				</>}
			</AdminEntityPageContainer>
		</Box>
	);
}