import {createSvgIcon} from "@plumeuk/shapeshift-common/icon";

export const IconNotification = createSvgIcon(
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g opacity="0.4" clipPath="url(#clip0_935_9443)">
			<path fillRule="evenodd" clipRule="evenodd" d="M4.00555 1.65351C5.0649 0.594161 6.50169 -0.000976562 7.99984 -0.000976562C9.49798 -0.000976562 10.9348 0.59416 11.9941 1.65351C13.0535 2.71286 13.6487 4.14965 13.6487 5.6478V10.6913C13.6487 11.0447 13.789 11.3837 14.0389 11.6336C14.3225 11.9172 14.5453 12.0239 14.857 12.0239C15.1725 12.0239 15.4284 12.2797 15.4284 12.5953C15.4284 12.9109 15.1725 13.1668 14.857 13.1668H1.14272C0.827127 13.1668 0.571289 12.9109 0.571289 12.5953C0.571289 12.2797 0.827127 12.0239 1.14272 12.0239C1.45437 12.0239 1.67719 11.9172 1.96076 11.6336C2.21067 11.3837 2.35106 11.0447 2.35106 10.6913V5.6478C2.35106 4.14965 2.9462 2.71286 4.00555 1.65351ZM5.99986 15.143C5.99986 14.6696 6.38362 14.2859 6.857 14.2859H9.14272C9.6161 14.2859 9.99986 14.6696 9.99986 15.143C9.99986 15.6164 9.6161 16.0001 9.14272 16.0001H6.857C6.38362 16.0001 5.99986 15.6164 5.99986 15.143Z" fill="white"/>
		</g>
		<defs>
			<clipPath id="clip0_935_9443">
				<rect width="16" height="16" fill="white"/>
			</clipPath>
		</defs>
	</svg>
	,
	"Notification",
	"0 0 20 20"
);