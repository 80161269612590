import {VideoPlayer} from "@plumeuk/shapeshift-common/videoPlayer";
import {useState} from "react";
import {makeStyles} from "tss-react/mui";
import {ILesson} from "@plumeuk/shapeshift-types";
import {ReactPlayerProps} from "react-player";
import {forwardRef} from "react";
import {VideoPlayerRef} from "@plumeuk/shapeshift-common/videoPlayer/videoPlayer";

const useStyles = makeStyles()((theme) => ({
	videoPlayerBackdrop: {
		position: "relative",
		width: "100%",
		paddingBottom: "56.25%",
		height: 0,
		overflow: "hidden",
		background: "black",
		boxSizing: "border-box",
		left: 0,
		top: 0,
		right: "0px",
		[theme.breakpoints.down("sm")]: {
			height: "250px"
		},
		"#bitmovin-player": {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			objectFit: "cover"
		}
	},
	videoPlayer: {
		zIndex: 0,
		background: "black",
		position: "absolute",
		opacity: 0,
		left: 0,
		transition: "opacity .5s ease-in",
		right: "0px"

	},
	videoPlayerSpacer: {
		height: "550px",
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			height: "250px"
		}
	}
}));

interface IPropsCustom {
	lesson?: ILesson,
}

export type IProps = Omit<ReactPlayerProps, "url"> & IPropsCustom;

export const LessonVideoPlayer = forwardRef<VideoPlayerRef, IProps>(function LessonVideoPlayer({lesson, ...props}, ref) {
	const {classes} = useStyles();
	const [videoReady, setVideoReady] = useState(false);
	if(!lesson?.videoUrl) return <></>

	return (
		<>
			<div className={classes.videoPlayerBackdrop}>
				<VideoPlayer
					moduleVideoType={lesson.videoType}
					ref={ref}
					onReady={() => {props.onReady();setVideoReady(true)}}
					className={classes.videoPlayer}
					style={{opacity: videoReady ? 1 : 0}}
					url={lesson.videoUrl}
					controls={true}
				/>
			</div>
		</>
	);
});